import { useEffect, useState } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import { useDispatch } from 'react-redux';
// COMPONENTS
import Accordion from 'Components/Accordion/Accordion';
// STYLES
import ViewWorkOrderModalWrapper from './ViewWorkOrderModalWrapper.style';
// ICONS
import CloseIcon from '@mui/icons-material/Close';
// REDUX
import { showToast } from 'Redux/General/Toast';
// HELPERS
import Api from 'Helpers/Api';
import { API_PATHS } from 'Helpers/Paths';
import { FileComponentWithDownload } from 'Styles/Constants';

const ViewWorkOrder = (props) => {
    const dispatch = useDispatch();

    const [details, setDetails] = useState({});
    const [images, setImages] = useState([])
    const [images1, setImages1] = useState([])

    const getDetails = async (WorkOrderNumber, Id) => {
        try {
            const response = await new Api().post(API_PATHS.BOOK_SERVICE_DETAILS, {
                data: { WOEnquiryId: Id, WorkOrderNumber: WorkOrderNumber }
            });

            if (response?.IsSuccess) {
                setDetails(response?.Data?.WOEnquiryDetails)
                setImages(response?.Data?.IssueSnaps)
                setImages1(response?.Data?.PurchaseProof)
            };

            if (response?.Message) dispatch(showToast(response?.Message));
        } catch (error) {
            dispatch(showToast(error?.response?.data?.Message || 'Internal Server Error'));
        }
    };


    useEffect(() => {
        getDetails(props?.data?.WorkOrderNumber, props?.data?.Id)
    }, [])
    console.log("🚀 ~ file: ViewWorkOrder.jsx:54 ~ useEffect ~ props?.data?.WorkOrderNumber:", props?.data)

    const FieldComp = ({ title, value }) => {
        return (
            <>
                <Grid item mt="20px" xs={3}>
                    {title} :
                </Grid>
                <Grid item mt="20px" xs={3}>
                    {value}
                </Grid>

            </>
        )
    }

    return (
        <ViewWorkOrderModalWrapper
            fullWidth
            open={props.open}
            onClose={props.handleClose}
            classes={{ root: 'dialog', paper: 'dialog' }}>
            <DialogTitle sx={{ m: 0, p: 2 }}>View Work Order</DialogTitle>

            <IconButton
                onClick={props.handleClose}
                sx={{
                    top: 8,
                    right: 8,
                    position: 'absolute',
                    color: (theme) => theme.palette.grey[500]
                }}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Accordion title="Customer Details" elevation={0} defaultExpanded>
                    <hr className="hr" />
                    <Grid container columnSpacing={2}>
                        <FieldComp title="Customer Name" value={details?.FirstName + " " + details?.LastName} />
                        <FieldComp title="Mobile Number" value={details?.Mobile || "-"} />
                        <FieldComp title="Email" value={details?.Email || "-"} />
                        <FieldComp title="Organization Name" value={details?.OrganizationName || "-"} />
                        <FieldComp title="Pan Number" value={details?.CustomerPANNo || "-"} />
                        <FieldComp title="Alternate Number" value={details?.AlternateMobileNo || "-"} />
                        <FieldComp title="Customer GST Number" value={details?.CustomerGSTNo || "-"} />
                    </Grid>
                </Accordion>

                <Accordion title="Service Address" elevation={0} defaultExpanded>
                    <hr className="hr" />
                    <Grid container columnSpacing={2}>

                        <FieldComp title="Name For Address" value={details?.NameForAddress} />

                        <FieldComp title="Address" value={details?.Address} />
                        <FieldComp title="Area Name" value={details?.AreaName} />
                        <FieldComp title="City Name" value={details?.CityName} />
                        <FieldComp title="State Name" value={details?.StateName} />
                        <FieldComp title="Pin code" value={details?.Pincode} />

                    </Grid>

                </Accordion>
                <Accordion title="Product Details" elevation={0} defaultExpanded>
                    <hr className="hr" />
                    <Grid container columnSpacing={2}>

                        <FieldComp title="Product Type" value={details?.ProductType} />
                        <FieldComp title="Product Make" value={details?.ProductMake} />
                        <FieldComp title="Product Model" value={details?.ProductModel || "Other"} />
                        {
                            details?.ProductModelId === 0 && details?.ProdModelIfOther !== "" &&
                            <FieldComp title="Product Model If Other" value={details?.ProdModelIfOther} />

                        }
                        <FieldComp title="Product Description" value={details?.ProductDescription || "Other"} />
                        {
                            details?.ProductDescriptionId === 0 && details?.ProdDescriptionIfOther !== "" &&
                            <FieldComp title="Product Model If Other" value={details?.ProdDescriptionIfOther} />

                        }
                        <FieldComp title="Product Number" value={details?.ProductNumber} />
                        <FieldComp title="Product Serial No" value={details?.ProductSerialNo} />
                        <FieldComp title="Issue Description" value={details?.IssueDescriptionName} />
                        <FieldComp title="Comment" value={details?.Comment} />

                    </Grid>

                </Accordion>
                <div>
                    <Typography fontSize={14} mb="5px" fontWeight="bold">
                        Issue Snaps :
                    </Typography>
                    <hr className="hr" />
                    <FileComponentWithDownload Attachment={images || []} />
                </div>
                <div>
                    <Typography fontSize={14} mb="5px" fontWeight="bold">
                        Proof Of Purchase :
                    </Typography>
                    <hr className="hr" />
                    <FileComponentWithDownload Attachment={images1 || []} />
                </div>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={props.handleClose}>
                    Close
                </Button>
            </DialogActions>
        </ViewWorkOrderModalWrapper>
    );
};

export default ViewWorkOrder;
