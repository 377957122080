import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// REDUX
import store from './Redux/Store';
// COMPONENTS
import Website from 'Components/Website';
// STYLES
import UtilityStyles from './Styles/Utils';
import { THEME_SETTINGS } from './themeSettings';

const theme = createTheme(THEME_SETTINGS);

const App = () => {
	return (
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
						<Website />
						<UtilityStyles />
					</BrowserRouter>
				</LocalizationProvider>
			</ThemeProvider>
		</Provider>
	);
};

export default App;
