export const API_PATHS = {
	SIGN_UP: 'CustomerRegistration/CustomerSignUp',

	GET_OTP: 'LoginAPI/OTPGenerate',

	LOGIN_BY_OTP: 'LoginAPI/LoginByOTP',
	VERIFY_TOKEN : 'LoginAPI/OTPVerification',

	LOGOUT: 'LoginAPI/Logout',

	GET_PROFILE: 'CustomerRegistration/GetCustomerProfileDetails',
	EDIT_PROFILE: 'CustomerRegistration/SaveCustomerDetails',

	MY_ADDRESSES: 'CustomerAddress/GetCustomerAddresses',
	SAVE_ADDRESS: 'CustomerAddress/SaveCustomerAddresses',
	SAVE_IS_DEFAULT_ADDRESS: 'CustomerAddress/SetCustomerDefaultAddresses',
	DELETE_ADDRESS: 'CustomerAddress/RemoveCustomerAddress',

	SUBMIT_CONTACT_US: 'ContactUs/SubmitContactUsForm',

	BOOK_SERVICE: 'WorkOrderEnquiry/SaveWOEnquiry',
	BOOK_SERVICE_DETAILS: 'CustomerWOEnquiry/WOEnquiryDetails',
	GET_OPERATING_SYSTEM_LIST_API :'OperatingSystemAPI/GetOperatingSystemList',
	MANAGE_COUNTRY_OF_PURCHASE_LIST_API : 'AddressAPI/GetCountryList',


	BUY: 'CustomerSOEnquiry/SaveSOEnquiry',
	BUY_DETAILS: 'CustomerSOEnquiry/SOEnquiryDetails',

	SELL: 'CustomerSellModule/SaveSellDetails',

	EXTENDED_WARRANTY: 'ExtendedWarranty/SaveExtendedWarranty',

	WORK_ORDER_ENQUIRY_LIST: 'CustomerWOEnquiry/WOEnquiryList',

	BUY_SERVICE_ENQUIRY_LIST: 'CustomerSOEnquiry/SOEnquiryList',
	SUBMIT_ENQUIRY_FEEDBACK: 'CustomerWOEnquiry/SubmitWOEnquiryFeedback',
	STATES_LIST: 'MasterDataAPI/GetStatesForSelectList',
	CITY_LIST: 'MasterDataAPI/GetCityForSelectList',
	AREA_LIST: 'MasterDataAPI/GetAreaForSelectList',
	PIN_CODE_LIST: 'MasterDataAPI/GetPincodeForSelectList',
	ADDRESS_TYPES_LIST: 'MasterDataAPI/GetAddressTypesListForDropdown',
	PRODUCT_TYPES_LIST: 'MasterDataAPI/GetProductTypesForSelectList',
	PRODUCT_TYPE_ALL_LIST : 'ProductTypeAPI/GetAllProductTypes',
	ISSUE_DESCRIPTION_LIST: 'IssueDescriptionAPI/GetIssueDescriptionList',
	PAYMENT_TERMS_LIST: 'MasterDataAPI/GetPaymentTermsList',
	PRODUCT_DESCRIPTION_LIST: 'ProductDescriptionAPI/GetProductDescriptionList',
	PRODUCT_CONDITIONS_LIST: 'MasterDataAPI/GetProductConditionsList',
	SERVICE_TYPE_LIST: 'MasterDataAPI/GetServiceTypeList',
	WARRANTY_TYPES_LIST: 'MasterDataAPI/GetWarrantyTypeList',
	ENQUIRY_STATUS_LIST: 'MasterDataAPI/GetEnquiryStatusMasterList',
	GET_PRODUCT_DESCRIPTION_API : 'ProductDescriptionAPI/GetProductDescriptionList',
	SAVE_CAREER_DETAILS : 'CareerAPI/SaveCareerDetails',
	BRANCH_LIST : 'BranchAPI/GetBranchList',
	
	GET_TRACK_WORK_ORDER_DETAILS : 'WorkOrder/WorkOrderTrackLog',
	
	GET_BANNER_LIST : "WebsiteAPI/GetBannerList",
	GET_SERVICES_LIST : "WebsiteAPI/GetOurServiceList",
	GET_PRODUCTS_LIST : "WebsiteAPI/GetOurProductList",
	GET_OFFER_LIST : "WebsiteAPI/GetOfferAdsList",
	GET_PRIVACY_POLICY : "WebsiteAPI/GetPrivacyAndPolicyList",
	GET_PAYMENT_POLICY : "WebsiteAPI/GetPaymentPolicyList",
	GET_REFUND_POLICY : "WebsiteAPI/GetRefundAndCancellationPolicyList",
	GET_TERMS_AND_CONDITIONS_LIST : "WebsiteAPI/GetTermsAndConditionList",
	GET_CAREER_LIST : "WebsiteAPI/GetCareerPostList",
	
	GET_TESTIMONIAL_LIST : "WebsiteAPI/GetTestimonialList",
	
	GET_PRODUCT_TYPE_API : 'ProductTypeAPI/GetAllProductTypes',
	PRODUCT_MAKES_LIST: 'MasterDataAPI/GetProductMakesForSelectList',
	PRODUCT_MODEL_LIST: 'MasterDataAPI/GetProductModelsList',
	PRODUCT_DESCRIPTION_API : 'ProductDescriptionAPI/GetProductDescriptionList',
	GET_QUOTATION_API : 'QuotationAPI/QuotationList',
	GET_QUOTATION_IMAGE :  'QuotationAPI/GetQuotationImage',
	PAYMENT_GENERATE_LINK_API : "PaymentGatewayAPI/GeneratePaymentLink",
	PAYMENT_STATUS_GET_API : "PaymentGatewayAPI/CheckPaymentStatus",

	QUOTATION_ACCEPT_REJECT_API : "QuotationAPI/QuotationAcceptNReject",
	GET_QUOTATION_DETAILS :"QuotationAPI/GetQuotationDetail",

	GET_INVOICE_IMAGE : "QuotationAPI/GetInvoiceImage",
	GET_PAYMENT_DETAILS_API : 'PaymentGatewayAPI/GetPaymentList',

	GET_PAYMENT_PART_LIST : "PaymentGatewayAPI/GetPaymentPartList",
	GET_NOTIFICATIONS_API : 'DashboardAPI/GetNotificationList',

	UPDATE_NOTIFICATION_STATUS :'DashboardAPI/UpdateNotification',
	OW_ENQUIRY_FEEDBACK_DETAILS : (id) => `CustomerWOEnquiry/WOEnquiryFeedbackDetails?WorkOrderId=${id}`
};
